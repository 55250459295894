/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.cursorPointer {cursor: pointer}

.errorRed {color: red;}

.accordion-button:not(.collapsed){
  background-color: lightgray;
  color: black;
}

.accordion-button:focus{
  box-shadow: 0 0 0;
}

.paus {
  background-color: #ffffcc;  /* Light yellow */
}

.infoLarge{
  font-size: x-large;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Set the desired height of the container */
}