a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* Additional Styles */

header {
  background-color: #f8f9fa;
}

.navbar {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  font-weight: bold;
}

.nav-link {
  padding: 0.5rem;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #f1f3f5;
}

@media (min-width: 576px) {
  .navbar-nav {
    flex-direction: row;
  }

  .navbar-nav .nav-link {
    margin-right: 0.5rem;
  }
}
